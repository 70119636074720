<template>
    <nav :class=" {fixed : fixed}">
      <div class="container">
        <div class="nav__wrapper">
          <div class="logo opacity0" v-animate-inview="'nav_logo'">
            <router-link to="/" >
              <CompanyLogo/>
            </router-link>
          </div>
          <div class="links nav_links" :class="{m_show: m_show, m_hide: m_hide}" @click="openMobile" key="">

            <router-link :class="{ 'active' : page === 'home', 'opacity0': true }" to="/" style="--delay:0.1s">Home</router-link>
            <router-link :class="{ 'active' : page === 'services', 'opacity0': true  }" to="/services" style="--delay:0.15s">Services</router-link>
            <router-link :class="{ 'active' : page === 'work', 'opacity0': true  }" to="/work" style="--delay:0.2s">Work</router-link>
            <router-link :class="{ 'active' : page === 'about', 'opacity0': true  }" to="/about" style="--delay:0.25s">About</router-link>
            <router-link :class="{ 'active' : page === 'payment', 'opacity0': true  }" to="/payment" style="--delay:0.3s">Payment</router-link>
            <router-link :class="{ 'active' : page === 'contact', 'opacity0': true  }" to="/contact" style="--delay:0.35s">Contact</router-link>
            
          </div>
         

          <span class="colorScheme_m" >
            <img :src="colorSchemeSrc" alt="" @click="colorScheme" class="opacity0" v-animate-inview="'nav_color_scheme'">
          </span>
          <div class="hamburger_wrapper opacity0" v-animate-inview="'nav_hamburger'">
              <div class="hamburger-container" @click="openMobile">
                <div class="hamburger-line"></div>
                <div class="hamburger-line"></div>
                <div class="hamburger-line"></div>
              </div>
            </div>
          </div>
      </div>
  </nav>
</template>

<script>
import CompanyLogo from "./ComapnyLogoC.vue";

export default {
  name: "NavbarC",
  data(){
    return {
      m_show: false,
      m_hide: false,
      colorSchemeSrc: "/sun.svg",
      lastScrollTop: 0,
      fixed: true,
    }
  },
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  methods:{
    openMobile: function(){
      this.m_show = this.m_show ? false : true
      this.m_hide = this.m_show ? false : true

      const lines = document.querySelectorAll('.hamburger-line')

      lines[0].classList.toggle('rotate-top')
      lines[1].classList.toggle('hide')
      lines[2].classList.toggle('rotate-bottom')
    },



    colorScheme: function(){
      document.getElementsByTagName("body")[0].classList.toggle("dark");
      if ( this.colorSchemeSrc === "/sun.svg" ){
        this.colorSchemeSrc = '/moon.svg' 
      }else{
        this.colorSchemeSrc = "/sun.svg"
      }
    },

    checkScroll() {

      if ( window.scrollY >= 0 && window.scrollY < 140 ){
        this.fixed = true;
      }
      else if (window.scrollY > this.lastScrollTop && !document.getElementsByClassName("m_show").length) {
        this.fixed = false;
      } else {
        this.fixed = true;
      }
      this.lastScrollTop = window.scrollY;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  components:{
    CompanyLogo
  },

}
</script>

