import { createRouter, createWebHistory } from 'vue-router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"


import HomeView from '../views/HomeView.vue'
import ServicesV from '../views/ServicesV.vue'
import WorkV from '../views/WorkV.vue'
import PaymentV from '../views/PaymentV.vue'
import AboutV from '../views/AboutV.vue'
import ContactV from '../views/ContactV.vue'
import PrivacyPV from '../views/PrivacyPV.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Websavvy | Home',
      metaTags: [
        {
          name: 'description',
          content: 'At WebSavvys, we are passionate about designing and developing websites, apps, and digital experiences that help our clients grow, innovate, and transform. We take the time to listen, learn, and understand your goals so that we can use our expertise to find the sweet spot of realistic and impactful solutions.'
        },
        {
          property: 'og:description',
          content: 'At WebSavvys, we are passionate about designing and developing websites, apps, and digital experiences that help our clients grow, innovate, and transform. We take the time to listen, learn, and understand your goals so that we can use our expertise to find the sweet spot of realistic and impactful solutions.'
        }
      ]
    }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesV,
    meta: {
      title: 'Websavvy | Services',
      metaTags: [
        {
          name: 'description',
          content: 'Discover how WebSavvys can help you achieve your digital goals with our expert services in web design, app development, and digital experiences. Our team listens, learns, and applies their expertise to deliver realistic and impactful solutions that transform your business. Explore our range of services and let us help you grow and innovate in the digital world.'
        },
        {
          property: 'og:description',
          content: 'Discover how WebSavvys can help you achieve your digital goals with our expert services in web design, app development, and digital experiences. Our team listens, learns, and applies their expertise to deliver realistic and impactful solutions that transform your business. Explore our range of services and let us help you grow and innovate in the digital world.'
        }
      ]
    }
  },
  {
    path: '/work',
    name: 'work',
    component: WorkV,
    meta: {
      title: 'Websavvy | Work',
      metaTags: [
        {
          name: 'description',
          content: 'Explore WebSavvys portfolio of successful projects, showcasing our expertise in designing and developing websites, apps, and digital experiences. Our work reflects our commitment to understanding our clients goals and delivering innovative solutions that drive growth and transformation. Browse our portfolio and see how we can help you achieve your digital aspirations.'
        },
        {
          property: 'og:description',
          content: 'Explore WebSavvys portfolio of successful projects, showcasing our expertise in designing and developing websites, apps, and digital experiences. Our work reflects our commitment to understanding our clients goals and delivering innovative solutions that drive growth and transformation. Browse our portfolio and see how we can help you achieve your digital aspirations.'
        }
      ]
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentV,
    meta: {
      title: 'Websavvy | Payment',
      metaTags: [
        {
          name: 'description',
          content: 'Make safe and convenient payments for WebSavvys expert digital services through secure payment portal, powered by Upwork Direct Payment. Trust in the confidentiality and ease of this trusted payment system to handle your transactions with ease. Simplify your payments and focus on growing your business with WebSavvys .'
        },
        {
          property: 'og:description',
          content: 'Make safe and convenient payments for WebSavvys expert digital services through secure payment portal, powered by Upwork Direct Payment. Trust in the confidentiality and ease of this trusted payment system to handle your transactions with ease. Simplify your payments and focus on growing your business with WebSavvys .'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutV,
    meta: {
      title: 'Websavvy | About',
      metaTags: [
        {
          name: 'description',
          content: 'Discover WebSavvys team of autonomous, collaborative, and outspoken developers, strategists, and designers who are passionate about solving problems with human-centric solutions. Our small, flat organization allows us to remain agile, transparent, and focused on delivering innovative solutions that make a difference. Learn more about our team and approach, and let us help you transform your digital presence.'
        },
        {
          property: 'og:description',
          content: 'Discover WebSavvys team of autonomous, collaborative, and outspoken developers, strategists, and designers who are passionate about solving problems with human-centric solutions. Our small, flat organization allows us to remain agile, transparent, and focused on delivering innovative solutions that make a difference. Learn more about our team and approach, and let us help you transform your digital presence.'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactV,
    meta: {
      title: 'Websavvy | Contact',
      metaTags: [
        {
          name: 'description',
          content: '"Get in touch with WebSavvys and let our team of experts help you with your digital needs. Our collaborative approach ensures that we listen and understand your goals, providing personalized solutions to meet your specific needs. Contact us today and let us help you grow, innovate, and transform with our professional digital services.'
        },
        {
          property: 'og:description',
          content: '"Get in touch with WebSavvys and let our team of experts help you with your digital needs. Our collaborative approach ensures that we listen and understand your goals, providing personalized solutions to meet your specific needs. Contact us today and let us help you grow, innovate, and transform with our professional digital services.'
        }
      ]
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPV,
    meta: {
      title: 'Websavvy | Privacy Policy',
      metaTags: [
        {
          name: 'description',
          content: 'Protecting your personal information is a top priority at WebSavvys. Our privacy policy outlines our commitment to safeguarding your data, explaining how we collect, use, and disclose information through our digital services. Trust in our transparent and secure approach to data privacy and learn more about our policies on our privacy policy page.'
        },
        {
          property: 'og:description',
          content: 'Protecting your personal information is a top priority at WebSavvys. Our privacy policy outlines our commitment to safeguarding your data, explaining how we collect, use, and disclose information through our digital services. Trust in our transparent and secure approach to data privacy and learn more about our policies on our privacy policy page.'
        }
      ]
    }
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})


router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
