import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { AnimateInViewDirective } from "@neeravp/vue-3-animate-in-view";



createApp(App).use(store).use(router).directive("animate-inview", AnimateInViewDirective).mount('#app')
