<template>
  <section class="section1 about">
    <div class="cover" v-animate-inview="'animateType1'">
      <div class="container">
        <h2 class="opacity0">At all times</h2>
        <h1 class="opacity0">We prioritize providing top quality work</h1>
      </div>
    </div>
  </section>


  <section class="container section2">
    <div class="marginTB">
      <div class="content" v-animate-inview="'animateType2'">
        <h2 class="B_Header opacity0">Life at <span>websavvys</span></h2>
        <p class="opacity0">At our company, we pride ourselves on solving problems, not just closing tickets. Our team is made up of autonomous, collaborative, and outspoken developers, strategists, and designers who are passionate about their work. By keeping our team small and our organization flat, we remain agile, transparent, and focused on human-centric solutions.</p>
      </div>
    </div>
  </section>
 
 
   <section class="section3 container">

    <div class="marginTBs">

      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/64110-web-development.gif" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">we are</h3>
          <h2 class="company_name opacity0">Modern Web Developers</h2>
          
          <p class="opacity0">As a web developer company that started after 2021, we bring a fresh and modern approach to our work. Our team is highly skilled in the latest web development technologies and we are always staying up-to-date with new trends and techniques.</p>
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">we offering</h3>
          <h2 class="company_name opacity0">Collaborative Problem Solving Solutions</h2>
          
          <p class="opacity0">With a focus on problem-solving, we collaborate closely with our clients to understand their unique needs and create tailored solutions that meet their goals. And because we keep our team small and our organization flat, we are able to maintain clear lines of communication and respond quickly to any changes or issues that may arise.</p>
        </div>
        <div class="cover middlePic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/73985-colleagues-working-together.gif" alt="">
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/101377-handshake-lottie.gif" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">we offering:</h3>
          <h2 class="company_name opacity0">long term partnership</h2>
          <p class="opacity0">At our company, we believe that building strong partnerships with our clients is key to long-term success. We are committed to working with you every step of the way to ensure that you achieve the results you are looking for.</p>
        </div>
      </div>

    </div>
  </section>



</template>

<script>


export default {
  name: 'AboutV',
}
</script>
