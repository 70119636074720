<template>
  <section class="section1 payment">
    <div class="cover" v-animate-inview="'animateType1'">
      <div class="container">
        <h2 class="opacity0">Trusted Payment System</h2>
        <h1 class="opacity0">Safe & Secure Upwork Payments</h1>
      </div>
    </div>
  </section>

 
  <section class="container s_section2">
     <div class="content marginTB">
      <h2 class="B_Header opacity0" v-animate-inview="'animateType13'">Experience <span>Fast and Secure</span> Payments with Upwork Direct Contract</h2>
      <div class="grid" v-animate-inview="'animateType14'">
        <div class="opacity0">
            <h2>Safe and secure</h2>
            <p> Upwork Direct Contract offers secure and reliable payment processing, ensuring that transactions are safe and protected from fraud.</p>
        </div>
        <div class="opacity0">
            <h2>Easy to use</h2>
            <p>Direct Contract allows clients to pay freelancers with just a few clicks, simplifying the payment process and saving time.</p>
        </div>
        <div class="opacity0">
            <h2>Flexibility</h2>
            <p> With Direct Contract, clients can pay their freelancers using a variety of payment methods, including credit cards, PayPal, and wire transfers, giving them the flexibility to choose the payment method that works best for them.</p>
        </div>
        <div class="opacity0">
            <h2>Reduced fees</h2>
            <p>Direct Contract can help clients save money by reducing transaction fees when compared to other payment options.</p>
        </div>
        <div class="opacity0">
            <h2>Direct communication</h2>
            <p> Direct Contract provides a direct communication channel between clients and freelancers, allowing them to discuss payments and work details in one centralized location.</p>
        </div>
      </div>
     </div>
  </section>
    

  <section class="section5 paddingTB sectionBeauty">
    <div class="container">

        <div class="grid">
          <div v-animate-inview="'animateType7'">
            <video controls class="opacity0">
              <source src="../assets/pictures/pic/upwork_escrow.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="content" v-animate-inview="'animateType8'">
            <h2 class="opacity0">Upwork Direct Payment</h2>
            <p class="p1 opacity0">Upwork Direct Payment is a payment processing service offered by Upwork, a popular online freelancing platform. It allows clients to pay freelancers directly through the platform using various payment methods, such as credit card, debit card, or PayPal.</p>
            <p class="p2 opacity0"><a class="gbutton gbuttonreverse" href="https://support.upwork.com/hc/en-us/articles/360025040794-Direct-Contracts-with-Clients" target="blank">Read More About Upwork Payment</a></p>
          </div>
        </div>
    </div>
  </section>
 


    <section class="marginTB psection1">
        <div class="container" v-animate-inview="'animateType2'">
            <h2 class="B_Header"><span>Upwork</span> Payment</h2>
            <p> Upwork Direct Contract held the payment for the project in Escrow, which means that the funds are kept in a secure account until the project is completed and approved by the client. This is done to protect both the client and the freelancer from any potential payment disputes.</p>
            <p>Once the project is completed and approved by the client, the funds are released from Escrow and transferred to the freelancer's account. If there are any disputes or issues with the project, the funds can be held in Escrow until the issue is resolved.</p>
            <p>In addition to the Escrow system, Upwork also provides Funds Protection to ensure that both clients and freelancers are protected in the event of any payment disputes or issues. If a dispute arises and the client and freelancer are unable to come to a resolution, Upwork's Dispute Resolution team can step in to mediate the situation and help resolve the issue.</p>
            <p>Overall, Upwork Direct Contract, Escrow, and Funds Protection provide a secure and reliable way for clients and freelancers to work together on projects, while ensuring that both parties are protected and that payments are processed safely and efficiently.</p>
        </div>
    </section>
</template>

<script>


export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
