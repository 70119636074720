<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet" viewBox="70 159 1816 568.1">
        <g class="web">
            <path d="M 70 328.2 C 70 331.6 110.9 555.1 112 557.7 L 113 560 L 140.9 560 C 156.8 560 169.1 559.6 169.4 559.1 C 170.1 558 187.2 454.5 189.5 437.5 C 190.4 430.9 191.5 424.7 191.9 423.6 C 192.3 422.5 193.2 426.8 194.3 435.1 C 196.5 452 213.8 557.8 214.5 559.1 C 214.9 559.6 227.1 560 243.1 560 L 271 560 L 272 557.7 C 272.9 555.4 314 330.6 314 327.7 C 314 326.2 311.8 326 290 326 C 276.8 326 266 326.2 266 326.4 C 266 326.6 261.5 354.2 256.1 387.7 C 250.6 421.1 245 456.4 243.5 466 C 242.1 475.5 240.8 483.5 240.6 483.7 C 240.5 483.8 239.4 476.2 238.1 466.7 C 236.2 452.2 217.1 335.1 215.5 328.2 L 215 326 L 192.1 326 L 169.2 326 L 168.6 328.7 C 165.9 341.6 148.6 444.9 146.4 461 C 144.9 472.3 143.4 481.8 143 482.3 C 142.7 482.7 141.8 478.9 141.1 473.9 C 139.2 459.4 117.9 327.7 117.4 326.8 C 117.1 326.4 106.3 326 93.4 326 L 70 326 L 70 328.2 Z" fill="#fff" stroke="none"></path>
            <path d="M 366.2 442.7 L 366.5 559.5 L 418.5 559.5 L 470.5 559.5 L 470.5 532.5 L 470.5 505.5 L 443.3 505.2 L 416 505 L 416 485.5 L 416 466 L 441.3 465.8 L 466.5 465.5 L 466.8 438.7 L 467 412 L 441.5 412 L 416 412 L 416 396 L 416 380 L 441.9 380 C 456.2 380 468.5 379.7 469.4 379.4 C 470.8 378.9 471 375.5 471 352.4 L 471 326 L 418.5 326 L 366 326 L 366.2 442.7 Z" fill="#fff" stroke="none"></path>
            <path d="M 531.2 442.7 L 531.5 559.5 L 567 559.8 C 586.8 559.9 605.9 559.6 610.1 559.1 C 646.5 554.3 667.7 526.1 665.7 485.5 C 665 472.7 663.2 465 658.8 456.2 C 655.3 449.3 647.7 441.5 641.4 438.5 C 637.9 436.8 637.7 436.6 639.5 435.6 C 644 433 651.4 425.1 654.6 419.5 C 659.8 410.1 662.1 399.7 662.2 385.5 C 662.4 354.4 649.6 336.6 621.3 328.8 C 614 326.8 610.9 326.6 572.2 326.2 L 531 325.9 L 531.2 442.7 Z M 603 379.2 C 608.8 382.1 611 386.4 611 394.5 C 611 398.8 610.3 401.9 608.8 405 C 605.5 411.5 601.6 413.3 590.3 413.8 L 581 414.2 L 581 395.6 L 581 377 L 589.8 377 C 596.9 377 599.4 377.4 603 379.2 Z M 605 466.2 C 611.4 469.5 614.1 475.2 614.1 485.2 C 614.1 501.9 607.7 507.9 590.3 508 L 581 508 L 581 486 L 581 464 L 590.8 464 C 599 464 601.3 464.4 605 466.2 Z" fill="#fff" stroke="none"></path>
        </g>
        
        <g class="savvy">
            <path d="M 774 323.1 C 758.3 325.1 748.7 329.4 738.4 339.2 C 729.6 347.6 724 357.6 720.9 370.6 C 719.1 378.3 718.9 381.6 719.3 395.5 C 719.6 408.6 720.2 412.9 722.1 419.5 C 727.2 436.3 737.2 449 754 460.1 C 758.7 463.2 767.7 469.2 774.2 473.4 C 787.8 482.3 790.4 485.6 790.4 493.7 C 790.4 503.4 784.5 508 772 508 C 758.8 508 742.7 502.9 727.9 494 C 723.4 491.2 719.4 489 718.9 489 C 717.3 489 717 493.4 717 519.5 L 717 546.9 L 720 549 C 725.5 552.9 739.4 558.8 747.5 560.6 C 758.5 563.1 781.8 563.1 791 560.6 C 807 556.2 821.7 545.3 829.4 532 C 838.1 517.2 842.1 496.2 840 477.5 C 836.9 448.9 828.3 437.2 794.4 414.9 C 774.5 401.7 769.5 397.2 768.5 391 C 767.7 386.4 769.9 380.8 773.3 378.5 C 781.6 373.1 802.3 377.7 821.2 389.1 C 823.3 390.4 825.3 391.1 825.6 390.7 C 826.7 389.6 840.9 340.7 840.4 339.8 C 839.3 338.1 821.5 329.8 813 327 C 808.2 325.4 799.9 323.7 794.7 323.1 C 784.1 321.9 783.4 321.9 774 323.1 Z" fill="#fff" stroke="none"></path>
            <path d="M 934.4 326.1 C 933.2 328.2 878.3 557.5 878.8 558.8 C 879.1 559.8 885.1 560 905.9 559.8 L 932.5 559.5 L 935.8 543.5 C 937.5 534.7 939.5 525.1 940.1 522.2 L 941.1 517 L 967.3 517.2 L 993.4 517.5 L 997.5 537.5 C 999.7 548.5 1001.9 558.1 1002.3 558.7 C 1002.9 559.7 1009.4 560 1029 560 C 1048.7 560 1054.9 559.7 1055.3 558.7 C 1055.6 557.9 1008.2 360.7 999.7 327.7 L 999 325 L 967 325 C 944.8 325 934.9 325.3 934.4 326.1 Z M 968.9 388.2 C 969.6 392.2 973 410.1 976.4 428 C 979.9 445.9 982.6 460.8 982.3 461.2 C 981.6 462.5 952 462.2 952 461 C 952 460.4 954.9 444.5 958.4 425.7 C 962 406.9 965.2 389.1 965.6 386.2 C 965.9 383.4 966.6 381 967 381 C 967.4 381 968.3 384.3 968.9 388.2 Z" fill="#fff" stroke="none"></path>
            <path d="M 1743.1 323.1 C 1739.5 323.5 1733.7 324.8 1730.1 325.9 C 1710.8 331.8 1695.6 348.5 1690.3 369.5 C 1687.8 379.5 1687.8 404.6 1690.3 415.3 C 1694.8 434.2 1705.7 448.5 1726 461.7 C 1759.5 483.7 1759.5 483.6 1759.5 493.6 C 1759.5 498.9 1759.1 500.3 1757 502.5 C 1753 506.8 1749.7 508 1741.8 508 C 1728.3 508 1708.6 501.4 1693.8 491.9 C 1691.2 490.2 1688.6 489.1 1688 489.5 C 1687.4 489.9 1687 500.2 1687 519 L 1687 547.9 L 1693.3 551.4 C 1715.7 564 1746.9 566.6 1768.8 557.7 C 1787.4 550.2 1802 532.6 1807.5 511 C 1809.7 502 1810.6 482.9 1809.1 473.3 C 1805.1 447.8 1796.9 437.1 1764.1 415.2 C 1741 399.7 1738 396.7 1738 388.9 C 1738 379.9 1744.9 375.2 1756.5 376.4 C 1765.7 377.3 1772.3 379.5 1783.9 385.6 C 1789.7 388.6 1794.7 390.7 1795.1 390.3 C 1795.9 389.4 1810 342.3 1810 340.5 C 1810 338.7 1795.2 331.3 1783.9 327.6 C 1777.3 325.4 1769.7 323.6 1763.9 323 C 1753.3 321.9 1752.1 321.9 1743.1 323.1 Z" fill="#fff" stroke="none"></path>
            <path d="M 1085.7 326.7 C 1085.3 327.4 1139.8 554.4 1141.3 558.2 C 1141.9 559.9 1144.2 560 1170 560 L 1198 560 L 1199 557.7 C 1200 555.4 1255 328.8 1255 327 C 1255 326.4 1245.1 326 1227.6 326 C 1202.3 326 1200.1 326.1 1199.6 327.7 C 1198.2 332.2 1174.6 451.1 1172.5 464 C 1171.2 472 1170.1 478.1 1170.1 477.6 C 1169.5 471.4 1144.3 340.6 1141.3 328.2 L 1140.8 326 L 1113.5 326 C 1098.4 326 1085.9 326.3 1085.7 326.7 Z" fill="#fff" stroke="none"></path>
            <path d="M 1285 327.5 C 1285 328.7 1337.2 545.6 1340 556.2 L 1341.1 560 L 1369.5 560 L 1397.9 560 L 1398.9 556.2 C 1402.3 543.5 1454 329.1 1454 327.7 C 1454 326.1 1451.7 326 1426.5 326 C 1411.4 326 1399 326.2 1399 326.4 C 1399 326.7 1393.4 354.5 1386.6 388.2 C 1379.8 421.9 1373.1 456 1371.9 464 L 1369.6 478.5 L 1366.9 462.5 C 1364.5 448.3 1341.7 332.2 1340.4 327.7 C 1339.9 326.1 1337.7 326 1312.4 326 C 1289.1 326 1285 326.2 1285 327.5 Z" fill="#fff" stroke="none"></path>
            <path d="M 1484.6 326.9 C 1484.3 327.4 1496.5 359.2 1511.7 397.7 L 1539.5 467.5 L 1540 513.5 L 1540.5 559.5 L 1564.4 559.8 C 1581.1 560 1588.7 559.7 1589.6 558.9 C 1590.8 558 1591 549.7 1591 512.4 L 1591 467 L 1618.2 399.3 C 1633.1 362 1645.5 330.3 1645.8 328.7 L 1646.2 326 L 1619.7 326 L 1593.2 326 L 1588.3 340.2 C 1585.5 348.1 1579.3 366.1 1574.4 380.3 C 1569.6 394.5 1565.3 405.4 1564.9 404.8 C 1564.5 404.1 1558.2 386.1 1551 364.7 L 1537.7 326 L 1511.4 326 C 1496.8 326 1484.9 326.4 1484.6 326.9 Z" fill="#fff" stroke="none"></path>
            <path d="M 1446.818 185.5 C 1213.368 200 965.181 215.4 895.357 219.7 L 768.456 227.5 L 768.106 265.8 L 767.872 304 L 779.568 304 L 791.263 304 L 791.263 275.1 L 791.263 246.2 L 799.217 245.6 C 803.545 245.2 950.445 236.2 1125.767 225.5 C 1301.088 214.7 1536.059 200.4 1647.988 193.5 C 1759.918 186.7 1853.719 180.9 1856.526 180.7 L 1861.439 180.2 L 1861.439 442.6 C 1861.439 586.9 1861.205 705 1860.853 705 C 1860.503 705 1677.111 693.8 1453.485 680 C 1229.86 666.3 990.562 651.6 921.672 647.4 C 852.901 643.3 795.357 639.7 793.954 639.4 L 791.263 639 L 791.263 610 L 791.263 581 L 779.568 581 L 767.872 581 L 767.872 619.5 C 767.872 653 768.106 658 769.626 658 C 770.562 658 946 668.8 1159.333 682 C 1372.784 695.2 1620.853 710.5 1710.561 716 C 1800.269 721.5 1876.526 726.2 1879.918 726.5 L 1886 727.1 L 1886 443.1 L 1886 159 L 1878.749 159.1 C 1874.654 159.2 1680.386 171.1 1446.818 185.5 Z" fill="#fff" stroke="none"></path>
        </g>
    </svg>
</template>

<style scoped>

    svg{
        height: 45px;
    }

    .savvy path{
        animation: 2s logoAnimate infinite ease-out;
    }

    @keyframes logoAnimate {
        from{
            fill: white;
        }        
        to{
            fill: #00BFFF;

        }
    }
</style>