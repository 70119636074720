<template>
  <NavbarC :page="this.page"/>
  <router-view/>
  <FooterC/>

</template>


<style>
@import './assets/styles/style.css';
</style>

<script>
import NavbarC from "./components/NavbarC.vue"
import FooterC from "./components/FooterC.vue"


export default {
  data(){
    return {
      page: "home",
      loading: true
    }
  },

  components:{
    NavbarC,
    FooterC,
    
  },


  watch: {
    $route(to) {
      this.currentPage = to.path;
      if ( this.currentPage == "/" ){
        this.page = "home";
      }else{
        this.page = this.currentPage.slice(1);
      }
    },
  },


}
</script>
