<template>
    <div class="contact_form">
        <form @submit.prevent="consultation_message">
            <h2>{{header}}</h2>
            <div class="input_div">
                <label for="name">Name</label>
                <input type="text" name="name" id="name" v-model="c_model.name" required>
                
            </div>
            <div class="input_div">
                <label for="company">Company</label>
                <input type="text" name="company" id="company" v-model="c_model.company" required>
                
            </div>
            <div class="input_div">
                <label for="email">Email</label>
                <input type="email" name="email" id="email" v-model="c_model.email" required>
                
            </div>
            <div class="input_div">
                <label for="pd">Project Details</label>
                <textarea name="pd" id="pd" v-model="c_model.details" required></textarea>
            </div>

            <button v-if="!sendingState" type="submit" class="btn-my">Send <span class="arrow-right"></span></button>
            <div v-if="sendingState" class="btn-my sendingAnimation" style="width:100%">
                <div>
                    Sending
                </div>
                <div class="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axiosInstance from '@/axios'

export default {
    name: "ContactComponent",
    props:{
        header:{
            type: String,
            required: true
        }
    },
    data(){
        return{
            c_model:{
                name:"",
                company:"",
                email:"",
                details:""
            },
            sendingState: false
        }
    },
    methods:{
        consultation_message: function(){
            if ( !this.sendingState ){
                this.sendingState = true; 
                
                axiosInstance.post('/email',this.c_model)
                .then( () => {
                    this.c_model = {
                        name:"",
                        company:"",
                        email:"",
                        details:""
                    }

                    this.sendingState = false; 
                })
                .catch( () => {
                    this.sendingState = true; 
                    this.c_model = {
                        name:"",
                        company:"",
                        email:"",
                        details:""
                    }
                    this.sendingState = false; 
                })
            }
        }
    }
}
</script>