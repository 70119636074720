<template>
    <section>
        <div class="marginTB">
            <div class="container">
                <div class="contact">
                    <h2 class="B_Header opacity0" v-animate-inview="'animateType13'">Ready to <span>Inovate</span></h2>
                    <div class="grid">
                        
                        <div v-animate-inview="'animateType16'">
                            <div style="width: 100%" class="mapg opacity0"><iframe width="100%" height="440" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=41.76767638281642,%2044.75460422462058+(Comapany)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe></div>

                            <div class="item opacity0">
                                <a class="gbutton" href="mailto:info@websavvys.com" target="__blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet" viewBox="0 67.38 511.9 377.12">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                        <path d="M665 4439 c-88 -10 -194 -46 -282 -94 -87 -49 -217 -178 -271 -270 -52 -90 -91 -199 -77 -220 9 -12 2488 -1529 2521 -1542 13 -5 2514 1520 2529 1542 7 11 3 35 -13 83 -37 108 -97 199 -191 292 -71 71 -102 94 -181 132 -186 91 18 82 -2095 84 -1029 1 -1902 -2 -1940 -7z"/>
                                        <path d="M0 2368 c0 -1084 0 -1071 50 -1207 85 -227 296 -411 539 -468 72 -17 183 -18 1971 -18 1788 0 1899 1 1971 18 249 58 461 246 543 484 46 131 47 144 44 1200 l-3 1002 -1190 -725 c-655 -399 -1211 -736 -1237 -750 -68 -35 -149 -41 -218 -17 -31 11 -586 343 -1255 752 -660 404 -1203 735 -1207 738 -5 2 -8 -452 -8 -1009z"/>
                                        </g>
                                    </svg>
                                    info@websavvys.com
                                </a>
                            </div>
                            <div class="item opacity0">
                                <a class="gbutton" href="https://www.google.com/maps/place/12+Giorgi+Brtskinvale+I+Turn,+T'bilisi/@41.7666098,44.7575933,17.5z/data=!4m5!3m4!1s0x4044720b7802cc65:0xc06f96458e41f92f!8m2!3d41.7676266!4d44.7545769" target="__blank">
                                    <svg class="pin" xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet" viewBox="64.11 21.19 383.81 469.1">
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                        <path d="M2390 4899 c-760 -67 -1408 -579 -1649 -1304 -160 -481 -127 -991 93 -1441 146 -297 317 -502 611 -731 354 -275 600 -554 839 -952 52 -86 104 -169 116 -184 75 -95 249 -93 323 5 14 18 78 121 142 228 135 225 205 325 326 465 141 164 281 292 494 450 201 150 341 296 471 490 374 563 427 1273 140 1880 -298 628 -893 1037 -1595 1095 -131 11 -176 11 -311 -1z m405 -1094 c229 -67 419 -225 525 -436 58 -115 81 -204 87 -345 12 -265 -75 -476 -273 -663 -68 -64 -106 -91 -187 -131 -145 -71 -233 -92 -387 -92 -186 0 -334 46 -487 150 -66 45 -174 154 -222 225 -42 61 -93 173 -117 257 -29 100 -27 337 4 444 93 321 354 555 689 616 89 16 269 4 368 -25z"/>
                                        </g>
                                    </svg>
                                    Georgia, Tbilisi, 12 Giorgi Brtskinvale I Turn
                                </a>
                            </div>
                        </div>
                        <ContactUsC :header="'free consultation'" class="opacity0" v-animate-inview="'animateType10'"></ContactUsC>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ContactUsC from '@/components/ContactUsC'

export default {
    components:{
        ContactUsC
    }
}
</script>

<style scoped>
.gbutton{
    text-transform: uppercase;
}

.gbutton svg{
    height: 12px;
    transform: translateY(-2px);
    fill: var(--secondary-color);
}
.gbutton .pin{
    height: 16px;
}


</style>