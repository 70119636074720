import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://back.websavvys.com/api', // replace this with your API base URL
  headers: {
    Authorization: `Bearer 1|AguQTR4MTTCPtMT7FaAjEdKsxA1AEE5SAMHNIZpC`,
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;