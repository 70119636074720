<template>
  <section class="section1 services" v-animate-inview="'animateType1'">
    <div class="cover">
      <div class="container">
        <h2 class="opacity0">your trusted web developement partner</h2>
        <h1 class="opacity0">Web Solutions Tailored to Your Business</h1>
      </div>
    </div>
  </section>

 
  <section class="container s_section2">
     <div class="content marginTB">
      <h2 class="B_Header opacity0" v-animate-inview="'animateType13'">Get <span>exactly</span> what you want</h2>
      <div class="grid" v-animate-inview="'animateType14'">
        <div class="opacity0">
          <p>A great website can give you what you need and connect with your audience. We can do it together</p>
        </div>
        <div class="opacity0">
          <p>We create personalized software solutions that you and your business needs</p>
        </div>
        <div class="opacity0">
          <p>We're experts at creating ecommerce solutions that really work for your business. From design to deployment, we've got you covered</p>
        </div>
      </div>
     </div>
  </section>

  <section class="ssection2 paddingTB sectionBeauty">
    <div class="container">
      <div class="content">
        <h2 class="B_Header" v-animate-inview="'animateType13'">Web Developement <span>flow</span></h2>
        <div class="grid" v-animate-inview="'animateType15'">
          <div class="item opacity0" >
            <span class="counter">1</span>
            <div class="content">
              <h2>Discovery and Planning</h2>
              <p>In this stage, the web development team meets with the client to discuss their goals and requirements for the website. The team gathers information about the client's business, target audience, and competition. Based on this information, the team creates a plan for the website's structure, design, and functionality.</p>
            </div>
          </div>

          <div class="item opacity0">
            <span class="counter">2</span>
            <div class="content">
              <h2>Design</h2>
              <p>Once the planning stage is complete, the web development team creates a design for the website. This includes the visual style, color scheme, layout, and user interface. The client may provide feedback on the design, and revisions may be made as necessary.</p>
            </div>
          </div>

          <div class="item opacity0">
            <span class="counter">3</span>
            <div class="content">
              <h2>Development</h2>
              <p>In this stage, the web development team starts building the website. This includes coding the HTML, CSS, and JavaScript for the front-end of the website, and setting up the back-end infrastructure. The team also integrates any necessary third-party tools, such as payment gateways or social media plugins.</p>
            </div>
          </div>


          <div class="item opacity0">
            <span class="counter">4</span>
            <div class="content">
              <h2>Testing</h2>
              <p>After the website is built, the web development team conducts thorough testing to ensure that the website is functioning properly and is compatible with all devices and browsers. The team may also conduct user testing to get feedback on the website's usability.</p>
            </div>

          </div>

          <div class="item opacity0">
            <span class="counter">5</span>
            <div class="content">
              <h2>Launch</h2>
              <p>Once the website has been tested and approved, it is ready to be launched. The web development team may handle the technical aspects of launching the website, such as setting up hosting and domain name registration.</p>
            </div>
          </div>  

          <div class="item opacity0">
            <span class="counter">6</span>
            <div class="content">
              <h2>Maintenance</h2>
              <p>After the website is launched, the web development team provides ongoing maintenance and support to ensure that the website continues to function properly and is up to date with the latest security patches and software updates. The team may also provide additional services, such as SEO optimization and content updates.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <!-- <section class="section3 container">

    <div class="marginTB">
      <div class="grid paddingTB container">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/expertteam.jpg" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">

          <h2 class="header opacity0">Expert Developers</h2>
          <p class="opacity0">At WebSavvys, we are a team of highly skilled and dedicated full stack web developers. With years of experience under our belts, we have the expertise and knowledge to create stunning and functional websites from the ground up. We understand the importance of having a well-designed website for a successful startup, which is why we put in all of our effort to craft custom solutions that not only look beautiful but also deliver results.</p>
        </div>
      </div>


      <div class="grid paddingTB container">
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="header opacity0">Custom Website Solutions</h2>
          <p class="opacity0">Whether you need a simple website to showcase your products and services or a complex platform to support your business operations, our team is up to the task. We use the latest technologies and design trends to build websites that are both visually appealing and user-friendly.</p>
        </div>
        <div class="cover middlePic transpPic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/cw.png" alt="">
        </div>
      </div>


      <div class="grid paddingTB container">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/partnersuccess2.jpg" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="header opacity0">Partnering for Success</h2>
          <p class="opacity0">Our approach is simple: we listen to your needs, understand your business goals, and craft solutions that meet both. With a focus on delivering exceptional customer service, we work closely with you every step of the way to ensure that you are completely satisfied with the end result.</p>
        </div>
      </div>
    </div>

  </section> -->


  <section class="section3 container">

    <div class="marginTBs">

      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/64110-web-development.gif" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">we are</h3>
          <h2 class="company_name opacity0">Expert Developers</h2>
          
          <p class="opacity0">At WebSavvys, we are a team of highly skilled and dedicated full stack web developers. With years of experience under our belts, we have the expertise and knowledge to create stunning and functional websites from the ground up. We understand the importance of having a well-designed website for a successful startup, which is why we put in all of our effort to craft custom solutions that not only look beautiful but also deliver results.</p>
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">we offer</h3>
          <h2 class="company_name opacity0">Custom Website Solutions</h2>
          
          <p class="opacity0">Whether you need a simple website to showcase your products and services or a complex platform to support your business operations, our team is up to the task. We use the latest technologies and design trends to build websites that are both visually appealing and user-friendly.</p>
        </div>
        <div class="cover middlePic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/121102-two-businessmen-work-in-the-office-collaboration-search-for-solutions.gif" alt="">
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/gif/72333-business-partners-handshaking-collaboration.gif" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h3 class="opacity0">WONDERED:</h3>
          <h2 class="company_name opacity0">Partnering for Success</h2>
          <p class="opacity0">Our approach is simple: we listen to your needs, understand your business goals, and craft solutions that meet both. With a focus on delivering exceptional customer service, we work closely with you every step of the way to ensure that you are completely satisfied with the end result.</p>
        </div>
      </div>

    </div>
  </section>


  <section class="paddingTB s_section4 sectionBeautyBottom">
    <div class="container">
     <div class="content">
      <h2 class="B_Header opacity0" v-animate-inview="'animateType13'"><span>TECHNOLOGIES</span>  WE SPECIALIZE IN</h2>
      <p class="opacity0" v-animate-inview="'animateType13'">We utilize the most modern languages, frameworks and technologies to ensure security, scalability and speed.</p>
      <div class="grid" v-animate-inview="'animateType15'">
        <div class="opacity0">
          <img src="../assets/pictures/logo/laravel-2.svg" alt="">
          <p>Laravel</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/javascript-Gray-1.svg" alt="">
          <p>Vanila Javascript</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/vuejs.png" style="height: 89px;" alt="">
          <p>Vuejs</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/vite.png" style="height: 89px;" alt="">
          <p>Vite</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/PHP-Gray.webp" alt="">
          
          <p>PHP</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/MYSQL-Gray.svg" alt="">
          
          <p>MYSQL</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/Python-Gray.svg" alt="">
          
          <p>PYTHON</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/typescript-Gray-1.svg" alt="">
          
          <p>TYPESCRIPT</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/linux-svgrepo-com.svg" alt="">
          
          <p>LINUX</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/Docker-Grey.svg" alt="">
          
          <p>DOCKER</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/Elastic-Search-Gray.svg" alt="">
          
          <p>Elastic search</p>
        </div>
        <div class="opacity0">
          <img src="../assets/pictures/logo/Graph.QL-Gray.svg" alt="">
          <p>GRAPH QL</p>
        </div>
      </div>
     </div>
    </div>
  </section>
 
</template>

<script>


export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
