<template>
    <section class="footer">
        <div class="marginTBFooter">
        <div class="container">
            <div class="grid">
            <div class="opacity20" v-animate-inview="'animateType18'">
                <ComapnyLogoC class="opacity0"/>
                <li class="opacity0">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="opacity0">
                    <router-link to="/services">Services</router-link>
                </li>
                <li class="opacity0">
                    <router-link to="/work">Work</router-link>
                </li>
                <li class="opacity0">
                    <router-link to="/about">About</router-link>
                </li>
                <li class="opacity0">
                    <router-link to="/payment">Payment</router-link>
                </li>
                <li class="opacity0">
                    <router-link to="/contact">Contact</router-link>
                </li>
                
            </div>
            <div v-animate-inview="'animateType20'">
                <h2 class="opacity0">Want to work <br> with us</h2>
                <router-link to="/contact" class="btn-my blue opacity0">Contact Us</router-link>
            </div>
            </div>

            <div class="grid bottom" v-animate-inview="'animateType19'">
                <p class="opacity0">©2023 All Rights Reserved. <router-link class="gbutton" to="/privacy-policy/">Privacy Policy</router-link></p>
                <p class="opacity0">WebSavvy Technologies ™</p>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import ComapnyLogoC from '@/components/ComapnyLogoC'

export default {
    name: "FooterC",

    components:{
        ComapnyLogoC
    }
}
</script>