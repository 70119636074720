<template>
  <section class="section1">
    <div class="cover" v-animate-inview="'animateType1'">
      <div class="container">
        <h2 class="opacity0">your trusted web developement partner</h2>
        <h1 class="opacity0">Your Digital Future Well Built</h1>
      </div>
    </div>
  </section>

  
  <section class="container section2" >
    <div class="marginTB">
      <div class="content" v-animate-inview="'animateType2'">
        <h2 class="B_Header opacity0">We Are <span>WebSavvys</span></h2>
        <p class="opacity0">We design and develop websites, apps and digital experiences that help our clients grow, innovate, and transform. We listen, learn and understand before we build. We identify your goals together, then use our expertise to find that sweet spot of realistic and impactful.</p>
      </div>
    </div>
  </section>
  

  
    <section class="ourUsage sectionBeauty">
      <div class="container paddingTB" >
        <div class="wrapper" v-animate-inview="'animateType3'">
          <div class="bheader_center">
            <div class="bheader_wrapper">
              <h2 class="B_Header opacity0">Why should you <span> use our service?</span> </h2>
            </div>  
          </div>      
          <div class="grid">
            <div class="opacity0">
              Our primary objective is to gain a thorough understanding of your requirements and desires. We believe in actively listening to you to deliver exactly what you want.
            </div>
            <div class="opacity0">
              If you're not entirely sure what you want, don't worry - we're here to help! With our years of experience, we can guide you in choosing what you need for your unique situation.
            </div>
            <div class="opacity0">The main motivation for us is customer satisfaction, it motivates us to become better, this is the secret of our success.</div>
          </div>
        </div>
      </div>
    </section>




  <section class="section3 container">

    <div class="marginTBs">

      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/netit.jpg" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">Stratify Solutions</h2>
          <h3 class="opacity0">Asked US:</h3>
          <h2 class="header opacity0">"HOW DO WE EMBRACE INNOVATION?"</h2>
          <p class="opacity0">Our favorite question. When you know what you want, but not what  you want, we go all-in to uncover the strategies that will get your business where you want it to go.</p>
          <a class="btn-my opacity0" href="https://darkslategray-eel-637705.hostingersite.com/" target="__blank">Open Application </a>
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">***</h2>
          <h3 class="opacity0">WANTED TO KNOW:</h3>
          <h2 class="header opacity0">"CAN YOU DEVELOP A NEW ADMIN DASHBOARD?"</h2>
          <p class="opacity0">When it comes to creating digital solutions, we can design virtually anything. If there's a more efficient approach, we'll guide you towards it. Our process begins with: Why? We leverage this insight to drive real growth and meaningful transformation.</p>
          <a class="btn-my opacity0" href="https://slategrey-swallow-649302.hostingersite.com/" target="__blank">Open Application </a>
        </div>
        <div class="cover middlePic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/orion.jpg" alt="">
        </div>
      </div>


      <div class="grid paddingTB">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/cmp-s.jpg" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">CMG</h2>
          <h3 class="opacity0">WONDERED:</h3>
          <h2 class="header opacity0">"WE NEED TO INNOVATE. WHAT NOW?"</h2>
          <p class="opacity0">The truth is, it depends. We typically host a few discovery workshops, followed by a series of sprints from proof of concept to production-ready. We’re agile, so you will be too.</p>
          <a class="btn-my opacity0" href="https://www.comparemygarage.com/" target="__blank">Open Application </a>
        </div>
      </div>

    </div>
  </section>


  <section class="section4 sectionBeauty">
    <hr class="sectionHR">
    <div class="marginTB">
      <div class="container">
        <div class="grid">
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/doubt-unscreen.gif" alt="">
            <h2 class="opacity0">WE THINK WITH YOU</h2>
            <p class="p1 opacity0">Innovation Strategy</p>
            <p class="p2 opacity0">Digital Product Strategy</p>
            <p class="p3 opacity0">Technology Architecture</p>
            <p class="p4 opacity0">Information Architecture</p>
          </div>
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/implementation-unscreen.gif" alt="">
            <h2 class="opacity0">WE ENVISION WITH YOU</h2>
            <p class="p1 opacity0">Digital Product Design</p>
            <p class="p2 opacity0">UX Research & Design</p>
            <p class="p3 opacity0">Website Design</p>
            <p class="p4 opacity0">Brand Identity Design</p>
          </div>
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/php-unscreen.gif" alt="">
            <h2 class="opacity0">WE BUILD WITH YOU</h2>
            <p class="p1 opacity0">Front End Development</p>
            <p class="p2 opacity0">Back And Development</p>
            <p class="p3 opacity0">Mobile App Development</p>
            <p class="p4 opacity0">Wordpress Development</p>
          </div>
        </div>
      </div>
    </div>
    <hr class="sectionHR">
  </section>

  <section class="section5">
    <div class="container">

        <div class="grid marginTB">
          <div v-animate-inview="'animateType7'">
            <video controls class="opacity0">
              <source src="../assets/pictures/pic/upwork_escrow.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="content" v-animate-inview="'animateType8'">
            <h2 class="opacity0">what about payment?</h2>
            <p class="p1 opacity0">We are providing you with one of the most secure upwork direct contract payment systems. Escrow is a technique that Upwork offers as assurance for both clients and the corporation to accomplish things right. Escrow: What Is It? Escrow is a contractual agreement where a third party holds funds or property until a specific condition is met.</p>
            <p class="p2 opacity0">Want to know more about Payment <router-link class="gbutton" to="/">Click Here</router-link> </p>
          </div>
        </div>
    </div>
  </section>




  <section class="section6 sectionBeauty">
    <div class="paddingTB">
      <div class="container">
        <div class="grid g2">
          <div class="leftWrapper" v-animate-inview="'animateType9'">
            <h2 class="opacity0">Get In Touch With us</h2>
            <p class="p1 opacity0">Please take a moment to complete the form provided and one of our representatives will be in touch with you shortly. Simply fill in the required fields and provide us with the necessary information to better understand your needs. We look forward to connecting with you and providing the assistance you require. Thank you for your interest in our services.</p>
          </div>
          <div class="contactUSC opacity0" v-animate-inview="'animateType10'">
            <ContactUsC :header="'free consulation'"></ContactUsC>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section6">
    <div class="marginTB">
      <div class="container">
        <div class="grid">
          <div v-animate-inview="'animateType11'">
            <h2 class="opacity0">WE DO OUR BEST</h2>
          </div>
          <div v-animate-inview="'animateType12'">
            <p class="opacity0">When we agree to build something, we’re agreeing to build the future of your business. Listening to you, guiding you, and satisfying you - the secrets to our success.</p>
            <router-link to="/services" class="btn-my opacity0">learn more about our services</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>




</template>

<script>
import ContactUsC from '@/components/ContactUsC'
 

export default {
  name: 'HomeView',
  data(){
    return {
      page: "home",

    }
  },
  components: {
    ContactUsC
  }
}
</script>
