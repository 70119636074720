<template>
  <section class="section1 work">
    <div class="cover" v-animate-inview="'animateType1'">
      <div class="container">
        <h2 class="opacity0">our work</h2>
        <h1 class="opacity0">involves bringing ideas to life</h1>
      </div>
    </div>
  </section>

  <section class="container section2">
    <div class="marginTB">
      <div class="content" v-animate-inview="'animateType2'">
        <h2 class="B_Header opacity0">Transforming Ideas into <span>RESULTS</span></h2>
        <p class="opacity0">Since 2022, we’ve worked with ambitious startups, global nonprofits, and Fortune 500s to design and develop digital experiences and custom applications that increase sales, boost brand awareness, and streamline operations.</p>
      </div>
    </div>
  </section>
  
  <section class="section3 container">

    <div class="marginTB">
      <div class="grid paddingTB container">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/crm.webp" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">Control Panel</h2>

          <h2 class="header opacity0">"Management Application for business"</h2>
          <p class="opacity0">Transform your business operations with our Control Panel Management Application. Designed to streamline workflows, enhance efficiency, and provide insightful data, it empowers your team to make informed decisions for optimal performance.</p>
          <a class="btn-my opacity0"  href="https://slategrey-swallow-649302.hostingersite.com/" target="__blank">Open Demo Application </a>
        </div>
      </div>


      <div class="grid paddingTB container">
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">Stratify Solutions</h2>

          <h2 class="header opacity0">"Trusted Business Solutions"</h2>
          <p class="opacity0">Stratify Solutions is dedicated to providing innovative business strategies and solutions tailored to your needs. Our team of experts works closely with you to ensure optimal engagement and success in your endeavors.</p>
          <a class="btn-my opacity0" href="https://darkslategray-eel-637705.hostingersite.com/" target="__blank">Open Application </a>
        </div>
        <div class="cover middlePic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/stratify_solutions.svg" alt="">
        </div>
      </div>


      <div class="grid paddingTB container">
        <div class="cover opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/cmp-s.jpg" alt="">
        </div>
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">CMG</h2>

          <h2 class="header opacity0">"Find Reliable Vehicle Specialists"</h2>
          <p class="opacity0">Compare My Garage is a platform that offers a hassle-free way to find reliable vehicle specialists. They provide full transparency and a convenient system to book a specialist in under 60 seconds. Say goodbye to the frustration of searching for hours online and the stress of incompetent specialists or overpriced services. Choose Compare My Garage for a hassle-free experience.</p>
          <a class="btn-my opacity0" href="https://www.comparemygarage.com/" target="__blank">Open Application </a>
        </div>
      </div>


      <div class="grid paddingTB container">
        <div class="content" v-animate-inview="'animateType5'">
          <h2 class="company_name opacity0">File Share</h2>
          <h2 class="header opacity0">"File Share Server"</h2>
          <p class="opacity0">Secure File Share Server with password encryption, allowing users to share files via email links. Ensure safe, convenient access to files while protecting sensitive information.</p>
          <a class="btn-my opacity0" href="https://drive.ggavasheli.com/" target="__blank">Open Application </a>
        </div>
        <div class="cover middlePic opacity0" v-animate-inview="'animateType4'">
          <img src="../assets/pictures/pic/14-Best-File-Sharing-Apps-for-Businesses-in-2024.webp" alt="">
        </div>
      </div>
    </div>

  </section>


  <section class="section4 sectionBeautyBottom">
    <div class="paddingTB">
      <div class="container">
        <div class="grid">
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/doubt-unscreen.gif" alt="">
            <h2 class="opacity0">WE THINK WITH YOU</h2>
            <p class="p1 opacity0">Innovation Strategy</p>
            <p class="p2 opacity0">Digital Product Strategy</p>
            <p class="p3 opacity0">Technology Architecture</p>
            <p class="p4 opacity0">Information Architecture</p>
          </div>
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/implementation-unscreen.gif" alt="">
            <h2 class="opacity0">WE ENVISION WITH YOU</h2>
            <p class="p1 opacity0">Digital Product Design</p>
            <p class="p2 opacity0">UX Research & Design</p>
            <p class="p3 opacity0">Website Design</p>
            <p class="p4 opacity0">Brand Identity Design</p>
          </div>
          <div v-animate-inview="'animateType6'">
            <img class="a6gif opacity0" src="../assets/pictures/gif/php-unscreen.gif" alt="">
            <h2 class="opacity0">WE BUILD WITH YOU</h2>
            <p class="p1 opacity0">Front End Development</p>
            <p class="p2 opacity0">Back And Development</p>
            <p class="p3 opacity0">Mobile App Development</p>
            <p class="p4 opacity0">Wordpress Development</p>
          </div>
        </div>
      </div>
    </div>
  </section>






</template>

<script>


export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
